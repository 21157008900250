<template>
  <div class="geo-wrapper">
    <h2>आयाेजनाहरुकाे संख्या का आधारमा आयाेजनाकाे वडागत वितरण</h2>
    <div class="geo-wrapper__content" v-if="hasData">
      <div class="map-section">
        <svg id="map"></svg>
        <Applegend></Applegend>
      </div>
      <ImsStats></ImsStats>
    </div>
    <NoDataFound v-else />
  </div>
</template>

<script>
import * as d3 from "d3";
import Applegend from "./legend.vue";
import ImsStats from "./imsStats.vue";
import { englishToNepaliNumber } from 'nepali-number';

export default {
  components: {
    Applegend,
    ImsStats
  },
  data() {
    return {
      area: null,
      currentArea: null,
      hasData: false
    };
  },
  computed: {
    imsData: function () {
      let data = this.$store.state.imsWardProject;
      let fiscalYear = this.$store.state.fiscal_year;

      data = data.filter((ele) => ele.fiscalyear === fiscalYear);
      
      this.hasData = data.length ? true : false;

      return data
    }
  },
  mounted() {
    this.mountMap();
    window.onresize = () => {
      this.mountMap();
    };
  },
  watch: {
    imsData: {
      handler() {
        setTimeout(() => {
          this.mountMap();
        }, 1000);
      }
    }
  },
  methods: {
    selectArea(data) {
      this.area = data;
    },
    openInfo(data) {
      this.currentArea = data;
    },
    closeInfo() {
      this.currentArea = null;
    },
    mountMap() {
      //remove previous map if present

      const myNode = document.getElementById("map");
      if (myNode) {
        myNode.innerHTML = "";
      }

      //return void if data is not fetched for sheet

      if (this.$store.state.isLoading || !this.hasData) return;

      // Set svg width & height

      let centered;
      let that = this;

      const mapCenter = {
        lat: 29.2544717,
        lng: 81.283471
      };

      const size = {
        height:
          d3.select(".map-section").node().getBoundingClientRect().height /
          1.15,
        width: d3.select(".map-section").node().getBoundingClientRect().width
      };

      const color = d3.scale
        .linear()
        .domain([1, 20])
        .clamp(true)
        .range(["#272A52", "#AB8AF8"]);

      const projection = d3.geo
        .equirectangular()
        .scale(size.width * 230)
        .center([mapCenter.lng, mapCenter.lat])
        .translate([size.width / 4, size.height / 1.8]);

      const path = d3.geo.path().projection(projection);

      const svg = d3
        .select("#map")
        .attr("width", size.width)
        .attr("height", size.height);

      // Add background
      svg
        .append("rect")
        .attr("class", "map-background")
        .attr("width", size.width)
        .attr("height", size.height)
        .style("fill", "#191932")
        .on("click", clicked);

      const g = svg.append("g");

      // const effectLayer = g.append("g").classed("effect-layer", true);
      const mapLayer = g.append("g").classed("map-layer", true);

      //for tooltip
      // var tooltipDiv = d3
      //   .select(".map-section")
      //   .append("div")
      //   .attr("class", "tooltip")
      //   .style("opacity", 0);

      // Load map data
      const geoJsonUrl =
        "https://gist.githubusercontent.com/anjesh/900387df12ced461326b9d60dfa81880/raw/debfc3622cdc6fac9c2be1fe6ab019a9b038ca80/mellekh.geojson";

      d3.json(geoJsonUrl, (error, mapData) => {
        var features = mapData.features;

        // Update color scale domain based on data
        color.domain([0, d3.max(features, nameLength)]);

        // Draw each area as a path
        mapLayer
          .selectAll("path")
          .data(features)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("vector-effect", "non-scaling-stroke")
          .attr("stroke", "#191932")
          .attr("stroke-width", "5px")
          .style("fill", fillArea)
          .on("mouseover", mouseover)
          .on("mouseout", mouseout);

        // .on("click", clicked);
      });

      function clicked(d) {
        var x, y, k;

        // Compute centroid of the selected path
        if (d && centered !== d) {
          var centroid = path.centroid(d);
          x = centroid[0];
          y = centroid[1];
          k = 4;
          centered = d;
          that.openInfo(d.properties);
        } else {
          x = size.width / 2;
          y = size.height / 2;
          k = 1;
          centered = null;
          that.closeInfo();
        }

        // Highlight the clicked area
        mapLayer.selectAll("path").style("fill", function (d) {
          return centered && d === centered ? "#D5708B" : fillArea(d);
        });

        // Zoom
        g.transition()
          .duration(750)
          .attr(
            "transform",
            "translate(" +
              size.width / 2 +
              "," +
              size.height / 2 +
              ")scale(" +
              k +
              ")translate(" +
              -x +
              "," +
              -y +
              ")"
          );
      }

      function mouseover(d) {
        // Highlight hovered area
        d3.select(this).style("stroke", "white").style("stroke-width", "3px");
        // Add text to selected polygon
        d3.select(this.parentNode)
          .append("text") //appending it to path's parent which is the g(group) DOM
          .attr("x", path.centroid(d)[0])
          .attr("y", path.centroid(d)[1])
          .attr("class", "mylabel") //adding a label class
          .text(`वडा ${englishToNepaliNumber(d.properties.ward)}`)
          .style('fill', '#fff')
          .style('font-weight', '600');
        if (d) {
          that.$store.dispatch("setImsWard", d.properties);
        }
      }

      function mouseout(d) {
        that.$store.dispatch("setImsWard", {});
        d3.select(this).style("stroke", "#021019").style("stroke-width", "1px");
        d3.selectAll(".mylabel").remove()//this will remove the text on mouse out
        // tooltipDiv.transition().duration(200).style("opacity", 0);
        // Reset area color
        mapLayer.selectAll("path").style("fill", (d) => {
          return centered && d === centered ? "#D5708B" : fillArea(d);
        });
      }

      // Get area name length
      function nameLength(d) {
        const n = nameFn(d);
        return n ? n.length : 0;
      }

      // Get area name
      function nameFn(d) {
        return d && d.properties ? d.properties.name : null;
      }

      // fill area color
      function fillArea(d) {
        let ward = d.properties.ward;
        let data = that.imsData.find((ele) => {
          if (parseInt(ele.ward) === ward) {
            return ele;
          }
        });

        let projects = data ? parseInt(data.totalnoprojects) : 0;

        switch (true) {
          case projects < 8:
            return "#000000";
          case projects < 17:
            return "#272A52";
          case projects < 25:
            return "#5B5A99";
          case projects > 25:
            return "#AB8AF8";
          default:
            return "#000000";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.geo-wrapper {
  padding: 100px 280px;
  @media all and (max-width: 3199px) {
    padding: 40px 36px;
  }
  h2 {
    margin-bottom: 32px;
    @media all and (max-width: 3199px) {
      margin-bottom: 24px;
    }
    @media all and (max-width: 1800px) {
      margin-bottom: 16px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  .area-title {
    position: absolute;
    top: 50px;
    left: 150px;
    color: white;
  }
  .area-info {
    background: white;
    position: absolute;
    top: 150px;
    right: 20px;
    height: 400px;
    width: 300px;
  }
  .map {
    &-section {
      padding-right: 64px;
      width: 60%;
      @media all and (max-width: 3199px) {
        width: 70%;
      }
    }
    &-background {
      fill: #05050f;
      pointer-events: all;
    }
    &-layer {
      fill: #08304b;
      stroke: #021019;
      stroke-width: 1px;
    }
  }

  div.tooltip {
    position: absolute;
    text-align: center;
    width: 80px;
    height: 28px;
    padding: 2px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
  }
}

#map {
  height: auto;
  width: 100%;
}
</style>
