import { render, staticRenderFns } from "./ims.vue?vue&type=template&id=14eb15cb&scoped=true&"
import script from "./ims.vue?vue&type=script&lang=js&"
export * from "./ims.vue?vue&type=script&lang=js&"
import style0 from "./ims.vue?vue&type=style&index=0&id=14eb15cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14eb15cb",
  null
  
)

export default component.exports