<template>
  <div class="stats-wrapper">
    <h2 class="stats-header" v-if="selectedWard">
      वडा <span>{{ englishToNepaliNumber(selectedWard) }}</span> का लागि आँकडा
    </h2>
    <h2 class="stats-header" v-else>सबै वडा का लागि आँकडा </h2>
    <div class="stats-section">
      <div class="stats-section__budget">
        कुल आयाेजनाको बजेट
        <span>रु. {{ nepaliNumberFormat(stats.total.budget, "ne") }}</span>
      </div>
      <div class="stats-section__projects">
        आयाेजनाहरु
        <span>{{ englishToNepaliNumber(stats.total.project) }}</span>
      </div>
    </div>

    <div class="stats-section">
      <div class="stats-section__budget">
          पूरा भएको बजेट
          <span> रु. {{ nepaliNumberFormat(stats.completed.budget, "ne") }}</span>
      </div>
      <div class="stats-section__projects">
          आयाेजनाहरु
          <span>{{ englishToNepaliNumber(stats.completed.project) }}</span>
      </div>
    </div>

    <div class="stats-section">
      <div class="stats-section__budget">
          सञ्चालित भएको बजेट
          <span>रु. {{ nepaliNumberFormat(stats.implementing.budget, "ne") }}</span>
      </div>
      <div class="stats-section__projects">
          आयाेजनाहरु
          <span>{{ englishToNepaliNumber(stats.implementing.project) }}</span>
      </div>
    </div>

    <div class="stats-section">
      <div class="stats-section__budget">
        <p>
          छनौट भएको बजेट
          <span> रु. {{ nepaliNumberFormat(stats.pipeline.budget, "ne") }}</span>
        </p>
      </div>
      <div class="stats-section__projects">
          आयाेजनाहरु
          <span> {{ englishToNepaliNumber(stats.pipeline.project) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { englishToNepaliNumber, nepaliNumberFormat } from "nepali-number";
export default {
  data() {
    return {
      selectedWard: null,
      stats: {
        total: {
          budget: 0,
          project: 0
        },
        completed: {
          budget: 0,
          project: 0
        },
        implementing: {
          budget: 0,
          project: 0
        },
        pipeline: {
          budget: 0,
          project: 0
        }
      }
    };
  },
  methods: {
    englishToNepaliNumber,
    nepaliNumberFormat,
    statsData() {
      let data = this.$store.state.imsWardProject;
      let fiscalYear = this.$store.state.fiscal_year;

      data = data.filter((ele) => ele.fiscalyear === fiscalYear);

      if (this.selectedWard && Object.keys(data).length > 0) {
        data = data.find((ele) => parseInt(ele.ward) === this.selectedWard);
        this.stats.total.budget = data.totalprojectsbudget || 0;
        this.stats.total.project = data.totalnoprojects || 0;

        this.stats.completed.project = data.noofcompletedprojects || 0;
        this.stats.completed.budget = data.totalbudgetcompletedprojects || 0;

        this.stats.implementing.budget =
          data.totalbudgetimplementingprojects || 0;
        this.stats.implementing.project = data.noofimplementingprojects || 0;

        this.stats.pipeline.project = data.noofpipeline_projects || 0;
        this.stats.pipeline.budget = data.totalbudgetpipelineprojects || 0;
      } else {
        this.stats.total.budget = 0;
        this.stats.total.project = 0;

        this.stats.completed.budget = 0;
        this.stats.completed.project = 0;

        this.stats.implementing.budget = 0;
        this.stats.implementing.project = 0;

        this.stats.pipeline.budget = 0;
        this.stats.pipeline.project = 0;

        for (const ele in data) {
          this.stats.total.budget += data[ele].totalprojectsbudget
            ? parseInt(data[ele].totalprojectsbudget)
            : 0;
          this.stats.total.project += data[ele].totalnoprojects
            ? parseInt(data[ele].totalnoprojects)
            : 0;

          this.stats.completed.budget += data[ele].totalbudgetcompletedprojects
            ? parseInt(data[ele].totalbudgetcompletedprojects)
            : 0;
          this.stats.completed.project += data[ele]
            .noofcompletedprojects
            ? parseInt(data[ele].noofcompletedprojects)
            : 0;

          this.stats.implementing.budget += data[ele]
            .totalbudgetimplementingprojects
            ? parseInt(data[ele].totalbudgetimplementingprojects)
            : 0;
          this.stats.implementing.project += data[ele]
            .noofimplementingprojects
            ? parseInt(data[ele].noofimplementingprojects)
            : 0;

          this.stats.pipeline.budget += data[ele].totalbudgetpipelineprojects
            ? parseInt(data[ele].totalbudgetpipelineprojects)
            : 0;
          this.stats.pipeline.project += data[ele].noofpipelineprojects
            ? parseInt(data[ele].noofpipelineprojects)
            : 0;
        }
      }
    }
  },
  computed: {
    selectedIms() {
      return this.$store.state.imsWard;
    },
    imsProjects() {
      return this.$store.state.imsWardProject;
    },
    fiscalYear() {
      return this.$store.state.fiscal_year;
    }
  },
  watch: {
    fiscalYear: {
      handler: "statsData"
    },
    imsProjects: {
      handler: "statsData",
      immediate: true
    },
    selectedIms: {
      handler(val) {
        if (val) {
          this.selectedWard = val.ward ? val.ward : null;
        }
      },
      immediate: true
    },
    selectedWard: {
      handler: "statsData"
    }
  }
};
</script>

<style lang="scss" scoped>
  .stats {
    &-wrapper {
      width: 40%;
      h2 {
        font-size: 22px;
        margin-bottom: 40px;
        span {
          color: $secondary;
          font-weight: 800;
        }
        @media all and (max-width: 1800px) {
          font-size: 20px;
        }
      }
      @media all and (max-width: 3199px) {
        width: 30%;
        h2 {
          margin-bottom: 24px;
        }
      }
    }
    &-section {
      background-color: $neutrals-midnight;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      padding: 56px;
      @media all and (max-width: 3199px) {
        margin-bottom: 24px;
        padding: 40px;
      }
      @media all and (max-width: 1800px) {
        border-radius: 10px;
        padding: 24px;
      }
      &__budget,&__projects {
        span {
          color: $neutrals-white;
          display: block;
          font-size: 60px;
          font-weight: 800;
          @media all and (max-width: 3199px) {
            font-size: 24px;
          }
          @media all and (max-width: 1800px) {
            font-size: 16px;
          }
        }
      }
      &__projects {
        border-left: 2px solid $neutrals-nightsky;
        padding-left: 48px;
        @media all and (max-width: 3199px) {
          padding-left: 24px;
        }
        @media all and (max-width: 1800px) {
          padding-left: 12px;
        }
      }
    }
  }
</style>
